import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default ({data}) => (
  <Layout>
    <SEO title="pH reakce prostředí" />
    <h1>pH reakce prostředí</h1>
    <p><strong>Reakce prostředí (pH reakce)</strong> je obecně dána koncentrací vodíkových iontů H+ a výrazně ovlivňuje druhovou skladbu rostlin na substrátech, kvantitativní, kvalitativní složení edafonu a taktéž zastoupení různých druhů ve vodních ekosystémech.</p>
    <hr />
    <h2>Obvyklé hodnoty pH v prostředí</h2>
    <p>V prostředí je pH reakce dána především poměrem H2CO3 a RCO3. Tedy kyseliny uhličité a solí této kyseliny.</p>
    <p>Prostředí mohou okyselovat tzv. <strong>kyselé deště</strong>, při nichž dochází k&nbsp;vymývání oxidů síry, dusíku a halogenů ze vzduchu a vznikají kyseliny. Na kyselosti srážek se podílí i CO2. Vzniklá H2SO3 (kyselina siřičitá), která není příliš stálá, je hlavním „viníkem“ při devastaci kyselými dešti. Ve velmi malém množství může vznikat i H2SO4 (kyselina sírová), která již likviduje i sochy a jinak odolné prvky.</p>
    <p>Dešťová voda má v&nbsp;neznečištěném prostředí v&nbsp;průměru pH 5,7, v&nbsp;ČR je v&nbsp;současnosti pH deště průměrně 4,5 a ve výrazněji znečištěných oblastech dosahuje pH 3,0. Sladké vody mohou mít pH od 3 do 10, případně do 11. Intenzivní fotosyntéza z vody odnímá CO2 a způsobuje tak vzrůst pH, naopak rašelinné vody s&nbsp;vysokým obsahem fulvokyselin a huminových kyselin mívají pH nízké.</p>
    <p>Půdní reakce je pak rozhodujícím způsobem ovlivněna matečnou horninou, je modifikována abiotickými a biotickými procesy, pohyby vody či opadem některých druhů rostlin (smrk, brusnice pH obvykle snižují a lípa s&nbsp;javorem pH zvyšují).</p>
    <hr />
    <h2>Vliv nízkého a vysokého pH</h2>
    <p><strong>Nízké pH</strong> omezuje příjem živin, osmoregulaci, výměnu plynů a aktivitu enzymů. Pro bakterie je nízké pH fatální, dochází k ochuzení edafonu a tím pádem i k omezení rozkladu organické hmoty. Výsledkem je hromadění organické hmoty, která se nerozkládá, a nedostatek živin. Ze zvětralin se pak též může <strong>mobilizovat toxický hliník</strong> (popř. železo či mangan).</p>
    <p>Obzvlášť pod smrky se vlivem nízkého pH může nakupit velká vrstva jehličí, což zároveň zvyšuje riziko požáru. Organickou hmotu nakonec rozkládají houby, jde ale o rozklad pomalý a nekvalitní, při němž vznikají podzoly. Mohou též odumřít symbiotické houby stromů, které jsou později nahrazeny patogenními houbami, jež rozkládají konečky kořenů stromů.</p>
    <p>V&nbsp;sladkých vodách s&nbsp;pH kolem 3 se již není schopen reprodukovat plankton, což v&nbsp;konečném důsledku vede k&nbsp;úhynu ryb (nedostatek potravy). Takto se přišlo ve Švédsku v&nbsp;70. letech minulého století na problém kyselých dešťů, vlivem kyselých dešťů zde došlo k&nbsp;okyselení jezer a úhynu ryb v&nbsp;nich.</p>
    <p><strong>Vysoké pH</strong> pak má vliv opět na osmoregulaci, je zde nadbytek především soli, vápníku a hořčíku. Dle výsledků rozborů bychom předpokládali, že je zde živin dostatek, jenže ty jsou vysrážené na povrchu koloidů a nemohou se uvolnit do roztoků, které by rostlina byla schopná strávit. Živiny jsou tak vyblokované, rostlina jich většinu nemůže využít, a tak paradoxně trpí jejich nedostatkem. Na tyto podmínky jsou však adaptované rostliny nazývané kalcifyty (či bazifyty).</p>
    <p>S měnícím se pH se liší také <strong>dostupnost živin na stanovišti</strong>, např.:</p>
    <ul><li>P, Fe, Mn &#8211; špatně přístupné v zásaditých půdách</li><li>K, Ca, Mg, P, Mo &#8211; špatně přístupné v kyselých půdách</li></ul>
    <p>Některé druhy snáší poměrně široké rozmezí pH prostředí, takové druhy nazýváme jako <strong>euryiontní</strong>. Druhy specializované na menší rozmezí hodnot pH jako <strong>stenoiontní</strong>, mohou být velmi nápomocné při bioindikaci a dělíme je na:</p>
    <ul><li>acidofilní (acidofyty) – pH do 5</li><li>neutrofilní (neutrofyty) – pH 5-7,3 (7,0)</li><li>alkalofilní, bazifilní (bazifyty) – ph nad 7,3 (7,0), v&nbsp;praxi je pH na vápencích do 8, a i to je extrém</li></ul>
    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>pH reakce prostředí</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/chemicky-boj-alelopatie/"><button className="inv">&larr; Chemický boj (alelopatie)</button></Link>
    <Link to="/ekologie-zivotni-prostredi/okyseleni-prostredi/"><button className="inv">Historie okyselení prostředí ČR &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
